import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/dcf0f3ecb19dac05d29428a634481424/2bbf0/img1.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAEEAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAH0tJleoxJNqWgtUGRc/wD/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQMxAhEyI//aAAgBAQABBQJJPLSI6xsjF0Rm/V1Dz//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/AYE//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAExEP/aAAgBAgEBPwF3HRH/xAAcEAABBAMBAAAAAAAAAAAAAAAAAQJxchAhMTL/2gAIAQEABj8CfpPRxBZH2NiyPth0j7YWT//EAB8QAAICAQQDAAAAAAAAAAAAAAABESExEGFxkbHw8f/aAAgBAQABPyGQGbj5osISgX53gWaJGBPR6odtPoxGShKW0r//2gAMAwEAAgADAAAAEBsuzv/EABgRAAMBAQAAAAAAAAAAAAAAAAABMRAR/9oACAEDAQE/EFM6IdP/xAAXEQEBAQEAAAAAAAAAAAAAAAABACEx/9oACAECAQE/EHqOE9XBf//EAB4QAQACAQUBAQAAAAAAAAAAAAEAESExQWFxsVGR/9oACAEBAAE/EHNWxZPhBjPFQgHAHQFbE2EXFfSCiKN1RH+vwmPKN7rpEII1m1Z0jtOXhA4sW24JUl5NMESstq/Cf//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/dcf0f3ecb19dac05d29428a634481424/e5166/img1.jpg",
              "srcSet": ["/static/dcf0f3ecb19dac05d29428a634481424/f93b5/img1.jpg 300w", "/static/dcf0f3ecb19dac05d29428a634481424/b4294/img1.jpg 600w", "/static/dcf0f3ecb19dac05d29428a634481424/e5166/img1.jpg 1200w", "/static/dcf0f3ecb19dac05d29428a634481424/2bbf0/img1.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ec280aef1a0129b9f4badb4c4f6337e7/2bbf0/img2.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBQEE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAdyKsvB5OQrmtJFU/wD/xAAcEAABBAMBAAAAAAAAAAAAAAABAgMhMQAQMhH/2gAIAQEAAQUCOk0TJnE05KyT6mnOxjXP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGhAAAgIDAAAAAAAAAAAAAAAAARAAIREgcf/aAAgBAQAGPwLQWuSsIv8A/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMZEQUYH/2gAIAQEAAT8hy5ICaYN7EkCRdl0dSGnY9L7hmhr7P//aAAwDAQACAAMAAAAQNwHw/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPxCahn//xAAWEQEBAQAAAAAAAAAAAAAAAAAQESH/2gAIAQIBAT8QLp//xAAeEAEAAgICAwEAAAAAAAAAAAABABEhQTFRYXGRsf/aAAgBAQABPxBt+o27fU/SY3VwYhhH1iFSvDuAk0oDeKlPrmPIfESx5uIUsCAPEQV7T//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/ec280aef1a0129b9f4badb4c4f6337e7/e5166/img2.jpg",
              "srcSet": ["/static/ec280aef1a0129b9f4badb4c4f6337e7/f93b5/img2.jpg 300w", "/static/ec280aef1a0129b9f4badb4c4f6337e7/b4294/img2.jpg 600w", "/static/ec280aef1a0129b9f4badb4c4f6337e7/e5166/img2.jpg 1200w", "/static/ec280aef1a0129b9f4badb4c4f6337e7/2bbf0/img2.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/18f24f13a7942807f290e829eb791b24/2bbf0/img3.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB9uLzjYVE1w0B/8QAGBAAAwEBAAAAAAAAAAAAAAAAAhAxAQD/2gAIAQEAAQUC1DCQzbwwkM//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ak//xAAdEAACAQQDAAAAAAAAAAAAAAAQEQEAITFBUXGB/9oACAEBAAE/IcibLNXanwHNXUAvcE//2gAMAwEAAgADAAAAEEcDcP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABYRAQEBAAAAAAAAAAAAAAAAABEBIP/aAAgBAgEBPxArj//EAB0QAQACAgMBAQAAAAAAAAAAAAEAESExUWFxQbH/2gAIAQEAAT8QasGWm3E/WWyJxvUtNgV0PncNV7gyRjmFmUvubPZ4FRMGWbvZ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/18f24f13a7942807f290e829eb791b24/e5166/img3.jpg",
              "srcSet": ["/static/18f24f13a7942807f290e829eb791b24/f93b5/img3.jpg 300w", "/static/18f24f13a7942807f290e829eb791b24/b4294/img3.jpg 600w", "/static/18f24f13a7942807f290e829eb791b24/e5166/img3.jpg 1200w", "/static/18f24f13a7942807f290e829eb791b24/2bbf0/img3.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4ec339bc398fac5d85ed1ab753028854/2bbf0/img4.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAe3EysG2SMM5XXVkf//EABsQAAEFAQEAAAAAAAAAAAAAAAIAAQMxMiEi/9oACAEBAAEFAnfqGitNUj+hNDUu1Dj/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAWEAADAAAAAAAAAAAAAAAAAAABEDD/2gAIAQEABj8CiX//xAAdEAEAAgICAwAAAAAAAAAAAAABABEQITFhQVGx/9oACAEBAAE/ISKeWOBAuoU8OIBX7nZpjvAi6n1n/9oADAMBAAIAAwAAABBPwTz/xAAWEQEBAQAAAAAAAAAAAAAAAAAQESH/2gAIAQMBAT8QJh//xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPxCL0R//xAAdEAEAAwACAwEAAAAAAAAAAAABABEhQXExYbHh/9oACAEBAAE/EAKWxoCKzLn0igqekQtjXmfaCPC3UPE5wh+Qrxy4noZW1lXUahW9T//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/4ec339bc398fac5d85ed1ab753028854/e5166/img4.jpg",
              "srcSet": ["/static/4ec339bc398fac5d85ed1ab753028854/f93b5/img4.jpg 300w", "/static/4ec339bc398fac5d85ed1ab753028854/b4294/img4.jpg 600w", "/static/4ec339bc398fac5d85ed1ab753028854/e5166/img4.jpg 1200w", "/static/4ec339bc398fac5d85ed1ab753028854/2bbf0/img4.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/eda681a73dae108e43ba692326a37780/2bbf0/img5.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB9udJFwYxTEHOr//EAB0QAAIBBAMAAAAAAAAAAAAAAAABAgMRITEEEkH/2gAIAQEAAQUCZcjprMlcjp7d0Un2JHnCzR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIQcjD/2gAIAQEABj8C4S0Onpn/xAAdEAEAAgEFAQAAAAAAAAAAAAABABEhEDFBUWGR/9oACAEBAAE/IVmo/GgTeUynnQvU4jwGVlZ9IiwuobHy4lDuw//aAAwDAQACAAMAAAAQ0MnA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHxABAAMAAQQDAAAAAAAAAAAAAQARITFBUWFxgZGh/9oACAEBAAE/EK3R91GHUB1XWee9gBrYJzkAHBq4Kp5hQLta9iIAmgvDJ2yB+AIgTkKfUd9ej8RQrSfdz//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/eda681a73dae108e43ba692326a37780/e5166/img5.jpg",
              "srcSet": ["/static/eda681a73dae108e43ba692326a37780/f93b5/img5.jpg 300w", "/static/eda681a73dae108e43ba692326a37780/b4294/img5.jpg 600w", "/static/eda681a73dae108e43ba692326a37780/e5166/img5.jpg 1200w", "/static/eda681a73dae108e43ba692326a37780/2bbf0/img5.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/604facc40421500934658bcb62a7e46d/2bbf0/img6.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEBQP/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAdtBMqANpFWYPXOYx//EABwQAAEEAwEAAAAAAAAAAAAAAAIAARExAxAyIv/aAAgBAQABBQJ9DRXKGjf00SFZO2WHj//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABcQAQADAAAAAAAAAAAAAAAAABABESD/2gAIAQEABj8C3ZL/AP/EABsQAAMAAgMAAAAAAAAAAAAAAAABERAhYYGx/9oACAEBAAE/IX2XcbwOnDvQqlqeDdQ3IGt4js9T/9oADAMBAAIAAwAAABBMAb3/xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxCoSwZ//8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBECH/2gAIAQIBAT8QdayD/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFRQXGhYf/aAAgBAQABPxBhBFcC9fs440cl/GkEpsaryfJxzTKU3k1TfaAYmt02KLutePcdj2n/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/604facc40421500934658bcb62a7e46d/e5166/img6.jpg",
              "srcSet": ["/static/604facc40421500934658bcb62a7e46d/f93b5/img6.jpg 300w", "/static/604facc40421500934658bcb62a7e46d/b4294/img6.jpg 600w", "/static/604facc40421500934658bcb62a7e46d/e5166/img6.jpg 1200w", "/static/604facc40421500934658bcb62a7e46d/2bbf0/img6.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1eb2bbc30bb304fe174ffac52c2f8f8b/2bbf0/img7.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7abktQLElVSoB//EABkQAAIDAQAAAAAAAAAAAAAAAAIxAAEQQf/aAAgBAQABBQLBVuCrwVOCv//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABUQAQEAAAAAAAAAAAAAAAAAACAB/9oACAEBAAY/AnD/AP/EAB0QAAMAAgIDAAAAAAAAAAAAAAABETFBIbEQcaH/2gAIAQEAAT8heclOw034NTZ2E2tzRecufE8v2LEWD//aAAwDAQACAAMAAAAQ+A5A/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGxABAQEBAQEBAQAAAAAAAAAAAREAITFBoVH/2gAIAQEAAT8QVVR2ak69s11fiy7EPU9fuh7+cUQta7oNKWNySXTTw3p2993RPzLVr0wRP7v/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/1eb2bbc30bb304fe174ffac52c2f8f8b/e5166/img7.jpg",
              "srcSet": ["/static/1eb2bbc30bb304fe174ffac52c2f8f8b/f93b5/img7.jpg 300w", "/static/1eb2bbc30bb304fe174ffac52c2f8f8b/b4294/img7.jpg 600w", "/static/1eb2bbc30bb304fe174ffac52c2f8f8b/e5166/img7.jpg 1200w", "/static/1eb2bbc30bb304fe174ffac52c2f8f8b/2bbf0/img7.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0231edf6ccb4f109a26ddb19dfbfcebb/2bbf0/img8.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBQT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAdyCJXBuVVDLoDR//8QAHBAAAQUAAwAAAAAAAAAAAAAAAQACAzEyEBEh/9oACAEBAAEFAjw2jabT3dEOUeZNj1R5/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFhABAQEAAAAAAAAAAAAAAAAAARAg/9oACAEBAAY/AtNb/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERIRAxQVFhkf/aAAgBAQABPyHYbwOqH3BuVzRSvRpk1Fna5KE23OD/2gAMAwEAAgADAAAAECwEgP/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EIQ//8QAFhEBAQEAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EC6f/8QAHhABAAMAAgIDAAAAAAAAAAAAAQARITFRQWFxsfH/2gAIAQEAAT8QYK3lwifF9z75fpbXItNJbw3Pfe9wHVMBVyyxcXpE2BWWMSUogtXByPf5OHb1n//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/0231edf6ccb4f109a26ddb19dfbfcebb/e5166/img8.jpg",
              "srcSet": ["/static/0231edf6ccb4f109a26ddb19dfbfcebb/f93b5/img8.jpg 300w", "/static/0231edf6ccb4f109a26ddb19dfbfcebb/b4294/img8.jpg 600w", "/static/0231edf6ccb4f109a26ddb19dfbfcebb/e5166/img8.jpg 1200w", "/static/0231edf6ccb4f109a26ddb19dfbfcebb/2bbf0/img8.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9425722a23b3a00ff843de332c222552/2bbf0/img9.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7SapYAmtpxQ0r//EABoQAAMBAQEBAAAAAAAAAAAAAAABAjEhEUL/2gAIAQEAAQUCerqjKfgukZ9E4InP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFRABAQAAAAAAAAAAAAAAAAAAASD/2gAIAQEABj8Caa//xAAfEAACAgIBBQAAAAAAAAAAAAAAAREhQVEQMWFxgbH/2gAIAQEAAT8hZ+AayRprsU3sjsJDJbIbY6WBUsn3Gr6sy7OOD//aAAwDAQACAAMAAAAQYAzw/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/ECGz/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhABAQADAAEFAAAAAAAAAAAAAREAITFBUWFxgaH/2gAIAQEAAT8Q7hm2vjInk5gsqlbyvV3CDw9fzIYQF3AJdYJiF47iDEecJSl9s7ddu5NQK3uChqCiOGfZz//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/9425722a23b3a00ff843de332c222552/e5166/img9.jpg",
              "srcSet": ["/static/9425722a23b3a00ff843de332c222552/f93b5/img9.jpg 300w", "/static/9425722a23b3a00ff843de332c222552/b4294/img9.jpg 600w", "/static/9425722a23b3a00ff843de332c222552/e5166/img9.jpg 1200w", "/static/9425722a23b3a00ff843de332c222552/2bbf0/img9.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/41cdc9fe05842e68743b6552921de5e3/2bbf0/img10.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHbm6lUBoLOI6Qon//EABsQAAICAwEAAAAAAAAAAAAAAAECACEDETEQ/9oACAEBAAEFAm8Xjdg451A24ljJbIJjpf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAAIDAAAAAAAAAAAAAAAAAAABEDAx/9oACAEBAAY/AqWa4//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBEGH/2gAIAQEAAT8hhaQe+KMJcxfvkaW4XUWg3sNC4tXtB1Xez//aAAwDAQACAAMAAAAQzMNM/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/EImT/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/ENgz/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFxQVGBof/aAAgBAQABPxBJ5n2YNMKnOajvEJnlJrIBjpTfGGfWIJEY9ZrKs/MFYHBmUNG+TkxGvpKJ76zWbCas/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/41cdc9fe05842e68743b6552921de5e3/e5166/img10.jpg",
              "srcSet": ["/static/41cdc9fe05842e68743b6552921de5e3/f93b5/img10.jpg 300w", "/static/41cdc9fe05842e68743b6552921de5e3/b4294/img10.jpg 600w", "/static/41cdc9fe05842e68743b6552921de5e3/e5166/img10.jpg 1200w", "/static/41cdc9fe05842e68743b6552921de5e3/2bbf0/img10.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/61c9d371251ae77e5693caaa2d267a56/2bbf0/img11.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgADBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHthgdakJRLSpv/xAAXEAADAQAAAAAAAAAAAAAAAAABAhAx/9oACAEBAAEFAjFwiLhi40XP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQAQESFBcVFhgf/aAAgBAQABPyHZE7YuUDtg7hesUBPAWGP/2gAMAwEAAgADAAAAEFvFfP/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ECH/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxAp/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITGxQWH/2gAIAQEAAT8QWVUZTBNFKz7OkBVqF3uZrtTpAulz8nmyanSY3tEmwr5c6T//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/61c9d371251ae77e5693caaa2d267a56/e5166/img11.jpg",
              "srcSet": ["/static/61c9d371251ae77e5693caaa2d267a56/f93b5/img11.jpg 300w", "/static/61c9d371251ae77e5693caaa2d267a56/b4294/img11.jpg 600w", "/static/61c9d371251ae77e5693caaa2d267a56/e5166/img11.jpg 1200w", "/static/61c9d371251ae77e5693caaa2d267a56/2bbf0/img11.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0318f106e031b527ccf3eba4b0dbc230/2bbf0/img12.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "139.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB9yEG4MnNcvWlZ//EABwQAAICAgMAAAAAAAAAAAAAAAECEjERMgAQIv/aAAgBAQABBQI9LTESyJLR24tOfeTFNf/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ASH/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAgMAAAAAAAAAAAAAAAAAARBBAhEg/9oACAEBAAY/Ap07Fjb5Yo//xAAZEAEAAwEBAAAAAAAAAAAAAAABABExEEH/2gAIAQEAAT8h1HgIaKiJMdK4IUSi794ITGS9I7j/2gAMAwEAAgADAAAAECfKfP/EABcRAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QkC0v/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8Qgz//xAAeEAEAAgICAwEAAAAAAAAAAAABABEhMUGxUWGRwf/aAAgBAQABPxDNwe36ztiqIrwrV1FgJIW6Ns7WJJbETPM0o4VfNTsiCNU/kUKslqMK7uf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/0318f106e031b527ccf3eba4b0dbc230/e5166/img12.jpg",
              "srcSet": ["/static/0318f106e031b527ccf3eba4b0dbc230/f93b5/img12.jpg 300w", "/static/0318f106e031b527ccf3eba4b0dbc230/b4294/img12.jpg 600w", "/static/0318f106e031b527ccf3eba4b0dbc230/e5166/img12.jpg 1200w", "/static/0318f106e031b527ccf3eba4b0dbc230/2bbf0/img12.jpg 1496w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      